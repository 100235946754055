<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <va-breadcrumbs>
          <va-breadcrumbs-item :label="$t('common.list')" target="/admin/user" to="/admin/user" />
          <va-breadcrumbs-item :label="$t('common.edit')" disabled />
        </va-breadcrumbs>
        <div class="table-wrapper mt-4">
          <page-title :pageTitle="$t('user.update_user')" />
          <va-inner-loading
            :loading="getUserLoading"
            color="primary"
            class="va-loading"
            :size="60"
            v-if="getUserLoading"
          />
          <user-form
            v-if="!getUserLoading"
            :initialData="user"
            :submitAction="submitAction"
            :loading="updateUserLoading"
          />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { GET_USER, UPDATE_USER } from '@mdb/core/src';
import UserForm from './UserForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ToastService from '@/components/helpers/ToastService';
import useUpdateUser from './useUpdateUser';

export default {
  components: { UserForm, PageTitle },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const id = route.params.id;

    const { item: user, loading: getUserLoading, error } = useGetEntity({ id }, GET_USER);

    const { result, updateUserLoading, submitForm, onDone } = useUpdateUser(id);

    onDone(() => {
      router.push({ name: 'list-user' });
    });

    return {
      error,
      user,
      getUserLoading,
      updateUserLoading,
      submitAction: submitForm
    };
  }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
